<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!--begin: Thumbnail-->
        <b-card>
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.thumbnail') }}
          </b-card-title>

          <AppMediaViewer :value="productThumbnail">
            <template v-slot:placeholder>
              <b-img src="/media/dummy/dummy-no-thumbnail.png" class="w-100">
              </b-img>
            </template>
          </AppMediaViewer>
        </b-card>
        <!--end: Thumbnail-->

        <!--begin: Product Basic Info-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.basicInformation') }}
          </b-card-title>

          <b-card-text>
            <template v-for="(i, index) in basicInfoList">
              <div :key="i + index" class="mb-5">
                <div class="font-weight-bolder text-capitalize mb-2">
                  {{ $t(`label.${i.name}`) }}:
                </div>
                <component
                  v-if="i.layout"
                  :is="i.layout"
                  :value="productObj[i.key]"
                  :id="id"
                  :itemKey="i.key"
                  :toDetailsRouteName="routeName.EXPERIENCE_COOKING_DETAILS"
                  :disabled="
                    productObj.deletedAt != '-' ||
                    !checkAccessRight(moduleName, [
                      permissionAction.PRODUCT_UPDATE
                    ]) ||
                    i.checkmenu
                  "
                  :storeActionsObj="visibilityStatusStoreActionsObj"
                  :switchActionsOnj="updateProductActionsObj"
                  :config="i.config"
                  :stateObj="productObj"
                  class="text-gray-600"
                >
                </component>
                <div v-else-if="i.key == 'price'" class="text-gray-600">
                  {{ productObj[i.key] | currency }}
                </div>
                <div v-else class="text-gray-600">
                  {{ productObj[i.key] }}
                </div>
              </div>
              <div
                v-if="i.key == 'averageRating' || i.key == 'isRated'"
                :key="index + 'separator'"
                class="separator separator-solid my-6"
              ></div>
            </template>
          </b-card-text>
        </b-card>
        <!--end: Product Basic Info-->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!--begin: Variation Info-->
        <b-card>
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.variation') }}
          </b-card-title>

          <AppSmartTable
            :headers="variationTableHeaders"
            :items="productObj.variation"
            :hidePagination="true"
          ></AppSmartTable>
        </b-card>
        <!--end: Variation Info-->

        <!--begin: Add On Info-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.addOnItems') }}
          </b-card-title>

          <AppSmartTable
            :headers="addOnTableHeaders"
            :items="productObj.addOn"
            :hidePagination="true"
          ></AppSmartTable>
        </b-card>
        <!--end: addOn Info-->

        <!--begin: Media Assets-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.mediaAssets') }}
          </b-card-title>

          <div v-if="productObj.assets">
            <AppMediaViewer
              v-if="productObj.assets.length >= 1"
              :value="productObj.assets"
            ></AppMediaViewer>
            <span v-else>{{ $t('label.thereAreNoMediaAssetToShow') }}</span>
          </div>
        </b-card>
        <!--end: Media Assets-->
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppTags from '@/views/components/bases/AppTags';
  import AppVisibilityStatusButton from '@/views/components/bases/AppVisibilityStatusButton';
  import AppTablePagination from '@/views/components/bases/pagination/AppTablePagination';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppSwitchWithLabel from '@/views/components/bases/AppSwitchWithLabel';
  import AppSwitch from '@/views/components/bases/AppSwitch';
  import AppAverageRating from '@/views/components/bases/AppAverageRating';
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { MediaType } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    PRODUCT_GET_PRODUCT,
    PRODUCT_SET_VISIBILITY,
    PRODUCT_UPDATE_PRODUCT,
    PRODUCT_INITIAL_GET_PRODUCT_STATE,
    PRODUCT_INITIAL_SET_VISIBILITY_STATE,
    PRODUCT_INITIAL_UPDATE_PRODUCT_STATE
  } from '@/core/store/product.module';

  export default {
    name: 'ProductDetails',
    mixins: [commonMixin],
    components: {
      AppCheckCircle,
      AppTablePagination,
      AppTags,
      AppVisibilityStatusButton,
      AppFeatureDetailsRelatedButton,
      AppSmartTable,
      AppSwitch,
      AppAverageRating,
      AppMediaViewer,
      AppSwitchWithLabel
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.PRODUCT,
      permissionAction: ACTION,
      id: null,
      productObj: {},
      basicInfoList: [
        {
          key: 'name',
          name: 'productName'
        },
        {
          key: 'id',
          name: 'id'
        },
        {
          key: 'username',
          name: 'createdBy'
        },
        {
          key: 'description',
          name: 'description'
        },
        {
          key: 'price',
          name: 'sellingPrice'
        },
        {
          key: 'sellingQuantity',
          name: 'sellingQuantity'
        },
        {
          key: 'foodType',
          name: 'foodType'
        },
        {
          key: 'filterType',
          name: 'filter',
          layout: APP_COMPONENT_NAME.APP_TAGS
        },
        {
          key: 'availableFrom',
          name: 'availabilityDate&TimeFrom'
        },
        {
          key: 'availableTo',
          name: 'availabilityDate&TimeTo'
        },
        {
          key: 'categoryName',
          name: 'category'
        },
        {
          key: 'foodCategory',
          name: 'foodCategory'
        },
        {
          key: 'recipe',
          name: 'relatedExperienceCooking',
          layout: 'AppFeatureDetailsRelatedButton'
        },
        {
          key: 'deliveryMethod',
          name: 'deliveryMethod'
        },
        {
          key: 'vehicleType',
          name: 'vehicleType'
        },
        {
          key: 'averageRating',
          name: 'averageRatings',
          layout: APP_COMPONENT_NAME.APP_AVERAGE_RATING,
          config: {
            routeName: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD_LIST,
            itemName: ''
          }
        },
        {
          key: 'isVisible',
          name: 'visibility',
          layout: APP_COMPONENT_NAME.APP_VISIBILITY_STATUS_BUTTON
        },
        {
          key: 'isActive',
          name: 'isActive',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isPopular',
          name: 'popular',
          layout: APP_COMPONENT_NAME.APP_SWITCH
        },
        {
          key: 'isTrending',
          name: 'trending',
          layout: APP_COMPONENT_NAME.APP_SWITCH
        },
        {
          key: 'isTop10',
          name: 'top10',
          layout: APP_COMPONENT_NAME.APP_SWITCH_WITH_LABEL,
          checkmenu: false
        },

        /*
        {
          key: 'isRecommended',
          name: 'recommended',
          layout: APP_COMPONENT_NAME.APP_SWITCH
        },
        */
        {
          key: 'isRated',
          name: 'rated',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        },
        {
          key: 'deletedAt',
          name: 'deletedAt'
        }
      ],
      visibilityStatusStoreActionsObj: {
        module: 'product',
        state: 'setVisibility',
        actionSet: PRODUCT_SET_VISIBILITY,
        actionInitial: PRODUCT_INITIAL_SET_VISIBILITY_STATE,
        successAction: () => {},
        title: i18nHelper.getMessage('label.setVisibility'),
        message: i18nHelper.getMessage('label.product')
      },
      variationTableHeaders: [
        {
          key: 'no',
          name: 'no',
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          sortable: true
        },
        {
          key: 'price',
          name: 'price',
          sortable: true
        },
        {
          key: 'isActive',
          name: 'isActive',
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        }
      ],
      addOnTableHeaders: [
        {
          key: 'no',
          name: 'no',
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          sortable: true
        },
        {
          key: 'price',
          name: 'price',
          sortable: true
        },
        {
          key: 'quantity',
          name: 'quantity',
          sortable: true
        },
        {
          key: 'isActive',
          name: 'isActive',
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        }
      ],
      updateProductActionsObj: {
        module: 'product',
        state: 'updateProduct',
        actionSet: PRODUCT_UPDATE_PRODUCT,
        actionInitial: PRODUCT_INITIAL_UPDATE_PRODUCT_STATE,
        successAction: () => {},
        errorAction: () => {},
        title: i18nHelper.getMessage('label.updatePreOrderFood')
      },
      productThumbnail: null
    }),
    computed: {
      productComplete() {
        return this.$store.state.product.product.complete;
      }
    },
    watch: {
      productComplete() {
        let response = this.$store.state.product.product;
        let title = i18nHelper.getMessage('label.getPreOrderFoodDetails');
        let initialStateAction = PRODUCT_INITIAL_GET_PRODUCT_STATE;
        let successAction = (response) => {
          this.importProductObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialProductDetails();
    },
    methods: {
      importProductObj(response) {
        let data = response.data;

        this.productObj = data;
        /*
          For average rating redirect to review and
          rating listing view and filter listing by item name
        */
        this.basicInfoList.find(
          (x) => x.key == 'averageRating'
        ).config.itemName = data.name;

        this.productThumbnail = {
          mediaPath: data.image,
          mediaType: MediaType.IMAGE
        };

        /* Top 10 button disable start*/
        const queryString = window.location.pathname.split('/');
        const menu = queryString[4];

        // null not equal menu
        if (
          this.productObj.filterCategory !== menu &&
          this.productObj.filterCategory !== null
        ) {
          const result = this.basicInfoList.find((o) => {
            return o.key === 'isTop10';
          });
          if (result) {
            // disabled button
            result.checkmenu = true;
          }
        }
        if (this.productObj.isTrending == true) {
          if (menu !== 'TRENDING') {
            const result = this.basicInfoList.find((o) => {
              return o.key === 'isTrending';
            });
            if (result) {
              result.checkmenu = true;
            }
          }
        }
        /* Top 10 button disable end */
      },
      getProduct(id) {
        this.$store.dispatch(PRODUCT_GET_PRODUCT, {
          id
        });
      },
      importSuccessActions() {
        let id = this.id;

        this.visibilityStatusStoreActionsObj.successAction = () => {
          this.getProduct(id);
        };

        this.updateProductActionsObj.successAction = () => {
          this.getProduct(id);
        };
      },
      initialProductDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.preOrderFood'),
              route: { name: ROUTE_NAME.PRE_ORDER_FOOD }
            },
            { title: i18nHelper.getMessage('label.preOrderFoodDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getProduct(id);
        this.initBreadCrumb(breadcrumbData);
        this.importSuccessActions();
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
